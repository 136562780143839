<template>
    <section class="crear-cotizacion-main container-fluid">
        <navbar-admin titulo="Cotizaciones"  />
        <titulo-divisor titulo="Datos de la cotización" />
        <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-5 my-3">
                <p class="input-label-top">Nombre cotización</p>
                <el-input v-model="model.nombre" size="small" clear="w-100" placeholder="Nombre cotización" />
            </div>
            <div class="col-12 col-md-6 col-lg-5 my-3">
                <p class="input-label-top">Oportunidad</p>
                    <el-select v-model="id_oportunidad" placeholder="Seleccionar oportunidad" filterable size="small" class="w-100" @change="listarEquipos">
                        <el-option v-for="item in oportunidades" :key="item.value" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
            <div class="col-12 col-md-6 col-lg-5 my-3">
                <p class="input-label-top">Equipo</p>
                <el-select v-model="id_equipo" placeholder="Seleccionar equipo" filterable size="small" class="w-100">
                    <el-option v-for="item in equipos" :key="item.value" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
            <div class="col-12 col-md-6 col-lg-5 my-3">
                <p class="input-label-top">País</p>
                <el-select v-model="model.id_configuracion_pais_utilidad" placeholder="Seleccionar país" filterable size="small" class="w-100" @change="utilidadPais">
                    <el-option v-for="item in paises" :key="item.value" :label="item.pais" :value="item.id" />
                </el-select>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6 col-lg-5 my-3">
                        <p class="input-label-top">Etapa</p>
                        <el-select v-model="id_etapa" placeholder="Seleccionar etapa" filterable size="small" class="w-100" @change="listarEtapasConfiguraciones">
                            <el-option v-for="item in equiposEtapas" :key="item.value" :disabled="item.disabled"  :label="item.nombre" :value="item.id" />
                        </el-select>
                    </div>
                    <div class="col-12 col-md-6 col-lg-5 my-3">
                        <p class="input-label-top">Configuración</p>
                        <div class="d-flex icon-option">
                            <el-select v-model="id_configuraciones" multiple  placeholder="Seleccionar configuración" filterable size="small" class="w-100">
                                <el-option v-for="item in configuracionEtapasEquipos" :key="item.value" :label="item.nombre" :value="item.id" />
                            </el-select>
                            <el-tooltip content="Agregar etapa y configuraciones" effect="light" visible-arrow>
                                <i class="icon-plus-circle f-25 cr-pointer ml-2" @click="addMoreStage" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div v-for="(item, index) in etapasConfig" :key="index" class="row justify-content-center border-top">
                    <div class="col-12 col-md-6 col-lg-5 my-3">
                        <p class="input-label-top">Etapa</p>
                        <div class="border br-5 py-1">
                            <p class="ml-2 f-14"> {{item.etapa.nombre}} </p> 
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-5 my-3">
                        <p class="input-label-top">Configuración</p>
                        <div class="d-flex icon-option">
                            <el-select v-model="item.v_model" multiple  placeholder="Seleccionar configuración" filterable size="small" class="w-100">
                                <el-option v-for="value in item.data" :key="value.id" :label="value.nombre" :value="value.id" />
                            </el-select>
                            <el-tooltip content="Agregar etapa y configuraciones" effect="light" visible-arrow>
                                <i class="icon-trash-can f-25 cr-pointer ml-2" @click="deleteStage(index, item)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 pt-3 border-top">
            <div class="col-auto ml-auto">
                <button class="btn f-12 btn-general" @click="crearCotizacion">Crear cotización</button>
            </div>
        </div>
        <!-- partials-->
        <modal-crear-cotizacion ref="openModalCrarCotizacion" @crear="guardarCotizacion" />
    </section>
</template>

<script>
import modalCrearCotizacion from './partials/modalCrearCotizacion'
import { mapGetters, mapActions, mapMutations} from 'vuex'
export default {
    components: {
        modalCrearCotizacion,
    },
    data() {
        return {
            model: {
                nombre:'',
                porcentaje_utilidad_pais:'',
                porcentaje_utilidad:0,
                id_configuracion_pais_utilidad: '',
                cotizador_equipos: [],
            },
            id_oportunidad:'',
            id_equipo:'',
            etapasConfig:[],
            id_etapa:'',
            id_configuraciones:[],
            more: true,
            equiposEtapas:[],
            etapasPayload:[]
        }
    },
    computed: {
        ...mapGetters({
            oportunidades: 'selects/selects/select_projects',
            equipos: 'cotizacion/cotizacionClientes/equipos',
            paises: 'cotizacion/cotizacionClientes/paises',
            estapasEquipos: 'cotizacion/cotizacionClientes/estapasEquipos',
            configuracionEtapasEquipos: 'cotizacion/cotizacionClientes/configuracionEtapasEquipos',
            cotizacionDetalle: 'cotizacion/cotizacionClientesDetalle/cotizacionDetalle'
        }),
    },
    async created(){
        await this.Action_get_select_projects();
        await this.Action_get_paises();
    },
    watch: {
        estapasEquipos(val) {
            this.equiposEtapas = val?.length ? this.estapasEquipos.map(e => ({...e, disabled:false})) : []
        }
    },
    methods: {
        ...mapActions({
            Action_get_select_projects: 'selects/selects/Action_get_select_projects',
            Action_get_equipos_ops: 'cotizacion/cotizacionClientes/Action_get_equipos_ops',
            Action_get_paises: 'cotizacion/cotizacionClientes/Action_get_paises',
            Action_get_etapas_equipos: 'cotizacion/cotizacionClientes/Action_get_etapas_equipos',
            Action_get_configuracion_etapas_equipos: 'cotizacion/cotizacionClientes/Action_get_configuracion_etapas_equipos',
            Action_post_crear_cotizacion: 'cotizacion/cotizacionClientesDetalle/Action_post_crear_cotizacion'
        }),
        ...mapMutations({
            set_configuracion_etapas_equipo: 'cotizacion/cotizacionClientes/set_configuracion_etapas_equipo'
        }),
        
        async listarEquipos(idOps){
            this.model.id_equipo = ''
            await this.Action_get_equipos_ops(idOps)
            this.listarEtapasEquipo(idOps)
        },

        async listarEtapasEquipo(idOps){
            await this.Action_get_etapas_equipos(idOps)
        },

        async listarEtapasConfiguraciones(id_etapa){
            let idOpConfig = this.estapasEquipos.find(e => e.id == id_etapa)
            let payload = {
                id_op: this.id_oportunidad,
                id_ops_producto_etapa: idOpConfig.id_oportunidades_producto_etapas
            }
            await this.Action_get_configuracion_etapas_equipos(payload)
        },

        crearCotizacion() {
            this.$refs.openModalCrarCotizacion.toggle()   
        },

        cotizadorEquipos(){
            this.model.cotizador_equipos = {
                id_oportunidad: this.id_oportunidad,
                id_producto: this.id_equipo,
            }
        },

        crearCotizacionPayload(){
            let datos = []
            let configuraciones = []
            this.etapasConfig.map(e => {
                e.data.map(o => {
                    if (e.v_model.includes(o.id)) {
                        configuraciones.push(o)
                    }
                })
                datos.push({id_etapa:e.etapa.id, cotizador_equipos_etapas_configuraciones:configuraciones })
                configuraciones = []
            })
            this.model.cotizador_equipos.cotizador_equipos_etapas = datos
        },

        async guardarCotizacion(){
            this.cotizadorEquipos()
            this.crearCotizacionPayload()
            await this.Action_post_crear_cotizacion(this.model)
            this.$router.push({
                name: 'cotizaciones.ver.detalle',
                params: {
                    id_cotizacion: this.cotizacionDetalle.id
                }
            })
        },

        addMoreStage(){
            if (this.id_etapa != '' && this.id_configuraciones.length) {
                let etapa
                this.equiposEtapas.forEach( e => {
                    if (e.id == this.id_etapa) {
                        etapa = e
                        e.disabled = true
                    }
                })
                this.etapasConfig.push({etapa, v_model:this.id_configuraciones, data:this.configuracionEtapasEquipos})
                this.id_etapa = ''
                this.id_configuraciones = []
                this.set_configuracion_etapas_equipo([])
            }
        },

        deleteStage(index, item){
            this.etapasConfig = this.etapasConfig.filter((_,idx)=> idx != index)
            this.equiposEtapas.map(e => {
                if (item.etapa.id == e.id) {
                    e.disabled = false
                }
                return item
            })
        },

        utilidadPais(id){
            let utilidad  = this.paises.find(e => e.id == id)
            this.model.porcentaje_utilidad_pais = utilidad.utilidad
            this.model.id_configuracion_pais_utilidad = utilidad.id
        }
        
    },

}
</script>

<style lang="scss" scoped> </style>