<template>
    <modal ref="crearCotizacion" titulo="Crear cotización" adicional="Aceptar" @adicional="crearCotizacion">
        <div class="m-3">
           <div class="row">
               <p class="col-12 f-14 text-center">¿Está seguro que quiere crear esta cotización?</p>
           </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.crearCotizacion.toggle()
        },
        crearCotizacion(){
            this.$refs.crearCotizacion.toggle()
            this.$emit('crear')            
        }
    }
}
</script>

<style lang="css" scoped>
</style>
